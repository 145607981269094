export const endpoints = {
  backend: "https://recibapp.herokuapp.com/",
  frontend: "https://reciba.app/",
  siteKey: "be3fd67b-1a61-4936-b57f-600e3765988f",
};

// export const endpoints = {
//   backend: "http://192.168.100.57:8000/",
//   frontend: "http://192.168.100.57:3000/",
//   siteKey: "10000000-ffff-ffff-ffff-000000000001",
// };
