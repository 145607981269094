export const FIELDS = [
  {
    id: 1,
    name: "email",
  },
  {
    id: 2,
    name: "password",
  },
];
